import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <footer id="footer">
    <ul>
      <li>
        <Link to="/terms-and-conditions" activeClassName="active">
          Terms & Conditions
          <div className="strike" />
        </Link>
      </li>
      <li>
        <Link to="/privacy-policy" activeClassName="active">
          Privacy Policy
          <div className="strike" />
        </Link>
      </li>
      <li>
        <a href="http://canvascreative.co">
          {`©${new Date().getFullYear()} Canvas`}
          <div className="strike" />
        </a>
      </li>
    </ul>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
