import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Page extends Component {
  static propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
  }
  render() {
    const { title, content } = this.props;

    return (
      <div className="content-page">
        <h1>{title}</h1>
        <div className="content">
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;