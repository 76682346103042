import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GlobalDispatchContext, GlobalStateContext } from '../components/theme'

const Header = ({ siteTitle }) => {
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    
    return (
      <header id="header">
        <div className="nav">
          <ul>
            <li
              onClick={() => dispatch({ type: "1" }) }
              className={state.theme === '1' ? 'active' : ''}
              >
              <span>
                01
                <div className="strike" />
              </span>
            </li>
            <li
              onClick={() => dispatch({ type: "2" }) }
              className={state.theme === '2' ? 'active' : ''}
              >
              <span>
                02
                <div className="strike" />
              </span>
            </li>
          </ul>
        </div>
        <div id="logo">
          <Link to="/" activeClassName="active">
            {siteTitle}
            <div className="strike" />
          </Link>
        </div>
        <div className="nav">
          <ul>
            <li
              onClick={() => dispatch({ type: "3" }) }
              className={state.theme === '3' ? 'active' : ''}
              >
              <span>
                03
                <div className="strike" />
              </span>
            </li>
            <li
              onClick={() => dispatch({ type: "4" }) }
              className={state.theme === '4' ? 'active' : ''}
              >
              <span>
                04
                <div className="strike" />
              </span>
            </li>
          </ul>
        </div>
      </header>
    );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header;