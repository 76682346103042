/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import "../styles/main.sass"
import bg from '../images/grain.png';

class Layout extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render () {
    const { children } = this.props;

    return (
      <div id="wrap" style={{backgroundImage: `url(${bg})`}}>
        <Header siteTitle={`Conquer`}/>
        <main id="main">{children}</main>
        <Footer siteTitle={`Conquer`} />
      </div>
    )
  }
}

export default Layout
